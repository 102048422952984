@import '../../../styles/commonConfig';

#ws_home{
  /* 托管云、快速伸缩、云安全、快速上手 */
  .quick_entrance{
    height: 100/$rem;
    ul{
      display: flex;
      height: 100%;
      align-items: center;
      background-color: #0e1127;
      li{
        &.quick_box{
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          &.quick_box_1{
            width: 92/$rem;
          }
          &.quick_box_2{
            width: 94/$rem;
          }
          .quick_box_container{
            a {
              display: block;
              height: 100%;
              img {
                display: block;
                width: 50/$rem;
                height: 50/$rem;
                margin: 0 auto 3/$rem;
              }
              p {
                line-height: 17/$rem;
                font-size: 12/$rem;
                color: #5F70B0;
                text-align: center;
              }
            }
          }
        }
        &.quick_line{
          width: 1/$rem;
          height: 56/$rem;
          background-color: #1C224E;
        }
      }
    }
  }
  /*.ws_wrap{
    background: #fff;
    ul.ws_box_row{
      overflow: hidden;
      li.ws_box_colum{
        float: left;
        width: 50%;
        height: 160/$rem;
        border-bottom: 2/$rem solid #bbb;
        a{
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          img{
            width: 72/$rem;
            height: 72/$rem;
          }
          p{
            width: 120/$rem;
            margin-left: 20/$rem;
            font-size: 28/$rem;
            font-weight: 400;
            color: #303742;
          }
        }
      }
    }
  }*/
  /* 各个模块标题部分 */
  .ws_serve_head{
    padding: 40/$rem 30/$rem 30/$rem;
    h1{
      line-height: 42/$rem;
      padding-bottom: 14/$rem;
      font-size: 30/$rem;
      color: #333;
      text-align: center;
    }
    p{
      line-height: 20/$rem;
      font-size: 14/$rem;
      color: #666;
      text-align: center;
    }
  }
  /* 享受安全极速的云服务*/
  .ws_product_tab{
    .ws_serve_head p{
      text-align: left;
    }
    display: block;
    overflow: hidden;
    .home_product_wrap{
      .home_product_tab{
        ul{
          border-top: 1/$rem solid #DEDEDE;
          li.home_product_nav{
            &.active a.home_product_nav_info i.icon_homepage, &.active a.home_product_nav_info p.tab_tit{
              color: #1394ff;
            }
            &.active a .icon_homepage.default{
              display: none !important;
            }
            &.active a .icon_homepage.active{
              display: inline-block !important;
            }
            &.active em.dropdownSwitch{
              color: #1394FF !important;
              transform: rotate(180deg);
            }
            a.home_product_nav_info{
              position: relative;
              display: flex;
              height: 60/$rem;
              padding: 0 18/$rem 0 55/$rem;
              border-bottom: 1/$rem solid #DEDEDE;
              justify-content: space-between;
              align-items: center;
              .wsc_product_icon{
                position: absolute;
                top: 50%;
                left: 15/$rem;
                transform: translateY(-50%);
                font-size: 0;
                .icon_homepage{
                  width: 30/$rem;
                  height: 30/$rem;
                  background-size: cover;
                }
                .icon_homepage.default{
                  display: inline-block;
                }
                .icon_homepage.active{
                  display: none;
                }
              }
              p.tab_tit{
                line-height: 22/$rem;
                color: #333;
                font-size: 16/$rem;
              }
              em.dropdownSwitch{
                color: #6a7480;
                font-size: 22/$rem;
                transition: transform .5s;
              }
            }
            .home_product_detail{
              height: 0;
              overflow: hidden;
              transition: height .5s;
              .home_product_detail_container{
                padding: 20/$rem 22/$rem 0 15/$rem;
                border-bottom: 1/$rem solid #DEDEDE;
                background-color: #F7F8FA;
                .home_product_item{
                  padding-bottom: 20/$rem;
                  a{
                    display: block;
                    h3{
                      line-height: 21/$rem;
                      margin-bottom: 7/$rem;
                      color: #333;
                      font-size: 16/$rem;
                    }
                  }
                  p{
                    line-height: 18/$rem;
                    color: #999;
                    font-size: 14/$rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /* 专业解决方案 */
  .ws_product{
    display: block;
    .ws_serve_head{
      padding-top: 56/$rem;
      padding-bottom: 55/$rem;
      background: url($img_baseURL + 'common/bg.png') no-repeat;
      background-size: cover;
      color: #fff;
      h1{
        color: #fff;
      }
      p{
        color: #fff;
      }
    }
    .wsc_tab{
      .nav_tabs{
        ul{
          li.home_solute_nav{
            &.active a.home_solute_nav_info p.tab_tit{
              color: #1394ff;
            }
            &.active em.dropdownSwitch{
              color: #1394FF!important;
              transform: rotate(180deg);
            }
            a.home_solute_nav_info{
              position: relative;
              display: flex;
              padding: 19/$rem 18/$rem 19/$rem 15/$rem;
              border-bottom: 1/$rem solid #DEDEDE;
              justify-content: space-between;
              align-items: center;
              p.tab_tit{
                line-height: 22/$rem;
                color: #333;
                font-size: 16/$rem;
              }
              em.dropdownSwitch{
                color: #6a7480;
                font-size: 22/$rem;
                transition: transform .5s;
              }
            }
            .home_solute_detail{
              height: 0;
              overflow: hidden;
              transition: height .5s;
              .home_solute_detail_container {
                padding: 24/$rem 15/$rem 10/$rem;
                border-bottom: 1/$rem solid #DEDEDE;
                p.des {
                  padding-right: 7/$rem;
                  color: #666;
                  font-size: 14/$rem;
                  line-height: 24/$rem;
                  overflow:hidden;
                  text-overflow:ellipsis;
                  display:-webkit-box;
                  /*! autoprefixer: off */
                  -webkit-box-orient: vertical;
                  /* autoprefixer: on */
                  -webkit-line-clamp:4;
                }
                a.more {
                  display: block;
                  line-height: 21/$rem;
                  padding: 12/$rem 0 16/$rem;
                  color: #1394ff;
                  font-size: 14/$rem;
                }
                .solute_log {
                  width: 366/$rem;
                  .solute_log_item {
                    display: inline-block;
                    width: 100/$rem;
                    height: 40/$rem;
                    line-height: 40/$rem;
                    margin: 0 22/$rem 5/$rem 0;
                    text-align: center;
                    img {
                      max-width: 100%;
                      max-height: 100%;
                      vertical-align: middle;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /* 超大规模数据中心 */
  .ws_node {
    padding-bottom: 28/$rem;
    background: #23283A;
    overflow: hidden;
    .ws_serve_head {
      h1{
        color: #fff;
      }
      p{
        color: rgba(255, 255, 255, .6);
      }
    }
    .node_map {
      display: block;
      width: 345/$rem;
      height: 167/$rem;
      margin: 6/$rem auto 30/$rem;
      background: url($img_baseURL + 'common/map_new.png') no-repeat;
      background-size: cover;
    }
    ul.node_list{
      display: flex;
      padding: 0 20/$rem;
      justify-content: space-between;
      flex-wrap: wrap;
      li{
        box-sizing: border-box;
        width: 160/$rem;
        height: 90/$rem;
        margin-bottom: 14/$rem;
        padding: 15/$rem;
        background-color: #2D334A;
        h3{
          display: flex;
          line-height: 30/$rem;
          font-size: 24/$rem;
          font-weight: bold;
          color: rgba(255, 255, 255, .8);
          justify-content: space-between;
          align-items: center;
          img{
            width: 30/$rem;
            height: 30/$rem;
            //border: 1/$rem dashed #888;
          }
        }
        p{
          line-height: 17/$rem;
          padding-top: 13/$rem;
          font-size: 12/$rem;
          color: rgba(255, 255, 255, .6);
        }
      }
    }
  }
  /* 提供安全合规的云计算服务 */
  .ws_authentication{
    overflow: hidden;
    ul.authentication_contents{
      display: flex;
      padding: 0 15/$rem 40/$rem;
      flex-wrap: wrap;
      justify-content: space-between;
      li{
        width: 158/$rem;
        height: 110/$rem;
        a {
          display: flex;
          height: 100%;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
          &:active{
            background-color: #F7F8FA;
          }
          img {
            display: block;
            width: 54/$rem;
            height: 54/$rem;
            margin: 14/$rem 0;
          }
          p {
            line-height: 17/$rem;
            font-size: 12/$rem;
            color: #666;
          }
        }
      }
    }
  }
}
