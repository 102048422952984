@import '../../../styles/commonConfig';

.big_view{
  position: relative;
  width: 100%;
  height: 474/$rem;
  overflow: hidden;
  /* banner内容区域 */
  ul.product_list{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: $level_z_index_1;
    li.produce_box{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      &.show{
        .text-box{
          -webkit-animation: tt_effect .6s .3s linear both;
        }
        .viewDetail{
          animation: opacity .5s .7s linear both;
          -webkit-animation: opacity .5s .7s linear both;
        }
      }
      i.bannerBg{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left:0;top:0;
        z-index:$level_z_index_1;
        background-size: cover;
        background-position: center;
      }
      .text-box{
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        top: 234/$rem;
        left:0;
        padding: 0 30/$rem 0 20/$rem;
        z-index: $level_z_index_2;
        h2.txt_info {
          p {
            &.txt_info_title {
              line-height: 50/$rem;
              font-size: 36/$rem;
              font-weight: 600;
              color: #fff;
              overflow:hidden;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-line-clamp:2;
              /*! autoprefixer: off */
              -webkit-box-orient: vertical;
              /* autoprefixer: on */
            }
            &.txt_info_des {
              line-height: 18/$rem;
              margin-top: 24/$rem;
              font-size: 14/$rem;
              color: #fff;
            }
          }
        }
        .viewDetail{
          display: block;
          width: 94/$rem;
          line-height: 18/$rem;
          margin-top: 20/$rem;
          padding: 8/$rem 0;
          border: 1/$rem solid #979797;
          font-size: 14/$rem;
          color: #fff;
          text-align: center;
        }
      }
    }
  }
  /* banner选择横条区域 */
  .product_btns{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: $level_z_index_1;
    .btns_area {
      display: flex;
      position: relative;
      justify-content: center;
      a {
        width: 30/$rem;
        height: 18/$rem;
        margin: 0 5/$rem;
        i {
          display: block;
          width: 100%;
          height: 3/$rem;
          background: rgba(255, 255, 255 , .1);
        }
      }
      .btns_active_line{
        position: absolute;
        width: 30/$rem;
        height: 3/$rem;
        left: 100/$rem;
        top: 0;
        z-index: $level_z_index_1;
        background: #1394FF;
        transition: left .3s;
      }
    }
  }
}
