<template>
  <div id="app">
    <!--<Home></Home>-->
    <div class="ws_pageContainer">
      <Header></Header>
      <div class="ws_pageContent">
        <transition name="fade" mode="out-in">
          <router-view/>
        </transition>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'app',
        components: {
            Header: () => import(/* webpackChunkName: 'group-header' */ '@/components/header/header'),
            Footer: () => import(/* webpackChunkName: 'group-footer' */ '@/components/footer/footer')
        }
    }
</script>

<style>
  #app{
    height: 100%;
  }
  .fade-enter-active{
    animation: fadeIn 1s both;
  }

  @keyframes fadeIn{
    0%{
      opacity:0.8;
    }
    100%{
      opacity:1;
    }
  }
</style>
